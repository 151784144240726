
/* eslint-disable */
  var ProgressBar = require('progressbar.js')
  import {
    defineComponent
  } from 'vue';
  import {
    mapGetters,
    mapState
  } from 'vuex';
  import gsap from "gsap";
  export default defineComponent({
    data() {
      return {
        bar: undefined,
        duration: 5000,
        audio: [],
        acttime: 0,
        clickBlock: false,
        // repeat: 1,
        // actualyTime: 0,
        thisTime: "0,00"
      }
    },
    setup() {

    },
    mounted() {
      this.duration = this.actualFrame.durationTIme *1000
      for (var s in this.actualFrame.sounds) {
        const sound = this.actualFrame.sounds[s];
        /* eslint-disable */
        this.addSound(s, sound.loop, sound.src)
      }
      this.startAnim()
    },
    methods: {
      startAnim() {
        $("#progress *").remove()
        this.playSound("background")
        var that = this;
        var tl = gsap.timeline({
          onComplete: function () {
            that.stagePlayer()
          }
        })
        tl.from(".anim", 1, {
          opacity: 0,
          "margin-top": "+150px",
          stagger: 0.2
        })
      },
      stagePlayer() {
       
        var that = this;
        this.bar = new ProgressBar.Circle("#progress", {
          strokeWidth: 4,
          duration: that.duration,
          color: 'red',
          trailColor: '#eee',
          trailWidth: 4,
          svgStyle: null,
          text: {
            style: {
              "margin-top": "-75%",
            }
          },
          step: function (state, circle) {
            const acttime = that.decimal(that.duration * circle.value(), 0);
            that.acttime = acttime;
            const t = that.duration - acttime;
            let ss = Math.floor((t / 1000) % 60)
            let ms = that.decimal((t / 1000 % 1) * 100, 0);
            ms = parseInt((ms < 100 ? ms : 99))
            let dss = (ss < 9) ? '0' + ss : ss;
            let dms = (ms < 9) ? '0' + ms : ms;

            const tim = dss + "," + dms;
            that.thisTime = tim;
            circle.setText(tim);
            if (t == 0) {
              that.bar.set(0);
              that.bar.destroy()
              that.stopAllSound();
              that.$store.dispatch('addAnswerTime', acttime)
              // that.$store.dispatch('actualyTime', acttime);
              // that.actualyTime += acttime
              // that.repeat++
              that.$store.dispatch('repeatCounter');
              if (that.repeat == that.actualFrame.repeatScene) {
                // that.bar.stop();
                that.playSoundCallback("end", function () {
                  that.stopAllSound();
                  that.$store.dispatch('failScene', true);
                })
              } else {
                that.playSoundCallback("adds", function () {
                  that.$store.dispatch('replayScene', true);
                })
              }
            }
          }
        });
        this.playSoundCallback("questions", function () {
          gsap.to("#progress, #timer", {
            opacity: 1,
            duration: 0.5
          })
          that.bar.animate(1);
          that.clickBlock = true;
        })
        // 

      },
      decimal(n: number, k: number) {
        const factor = 10 ** (k + 1);
        const nn = Math.round(Math.round(n * factor) / 10);
        return nn / (factor / 10);
      },
      stopSound(key) {
        this.audio[key].src.pause();
        this.audio[key].src.currentTime = 0;
      },
      stopAllSound() {
        for (var s in this.audio) {
          this.audio[s].src.pause();
          this.audio[s].src.currentTime = 0;
        }
      },
      onOffSound() {
        for (var s in this.audio) {
          (this.sound) ? this.audio[s].src.volume = 1: this.audio[s].src.volume = 0
        };
      },
      addSound(key, loop, sound) {
        this.audio[key] = {
          src: undefined,
          loop: false
        }
        this.audio[key].src = new Audio(require(`@/assets/${sound}`));
        this.audio[key].loop = loop
      },
      playSound(key) {
        if (this.audio[key].loop) {
          this.audio[key].src.loop = true
        }
        this.onOffSound();
        this.audio[key].src.play();
      },
      playSoundCallback(key, callBack) {
        if (this.audio[key].loop) {
          this.audio[key].src.loop = true
        }
        this.audio[key].src.play();
        this.audio[key].src.onended = function () {
          callBack()
        };
      },
      response(event, resp) {
        this.stopAllSound();
        this.clickBlock = false;
        var that = this;
        var tl = gsap.timeline({
          onComplete: function () {
            gsap.set(".answerBtb", {
              backgroundColor: 'rgba(0, 0, 0, 0.01)'
            })
          }
        });
        const terget = $(event.currentTarget);
        that.$store.dispatch('addAnswerTime', that.acttime);
        if (resp) {
          that.playSoundCallback("good", function () {
            that.$store.dispatch('startPlayerTwoWay', true);
          })
          tl.fromTo(terget, 1, {
            backgroundColor: 'rgba(0, 255, 0, 0.01)'
          }, {
            backgroundColor: 'rgba(0, 255, 0, 0.99)',
            onComplete: function () {
              that.$store.dispatch('addPoint', true);

            }
          });
        } else {
          that.playSoundCallback("bad", function () {

            //Restart ANim
            // that.startAnim()
            that.$store.dispatch('replayScene', true);
          })
          tl.fromTo(terget, 1, {
            backgroundColor: 'rgba(255, 0, 0, 0.01)'
          }, {
            backgroundColor: 'rgba(255, 0, 0, 0.99)',
            onComplete: function () {}
          });
          that.$store.dispatch('addPoint', false);
        }
        this.bar.stop();
      }
    },
    computed: {
      ...mapState(['actualFrame', 'frame', 'runStage', "showFrame", "sound"]),
      ...mapGetters({
        actualyTime: 'actualyTime',
        repeat: "repeat",
        mobile: "mobile"
      }),
    },
    watch: {
      sound(newValue, oldValue) {
        this.onOffSound()
      }
    }
  })

