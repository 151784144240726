
/* eslint-disable */
  import {
    defineComponent
  } from 'vue'
  import M from 'materialize-css'
  import _, {
    map
  } from 'underscore';
  import PlayerView from './views/PlayerView.vue';
  export default defineComponent({
    components: {
      PlayerView,
    },
    data() {
      return {
        windowHeight: 0,
        windowWidth: 0,
        mobie: false
      }
    },
    setup() {

    },
    mounted() {
      M.AutoInit();
      var page = this;
      page.getPageSize();
      $(window).resize(_.debounce(function () {
        page.getPageSize();
      }, 150));
    },
    methods: {
      getPageSize() {
        this.windowHeight = document.documentElement.clientHeight;
        this.windowWidth = document.documentElement.clientWidth;
        if (this.windowHeight > 600 && this.windowWidth > 800) {
          this.mobie = false
          this.$store.dispatch('mobileTriger', false);
        } else {
          this.mobie = true
          this.$store.dispatch('mobileTriger', true);
        }
      }

    }
  })

