import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row app" }
const _hoisted_2 = {
  key: 1,
  class: "info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayerView = _resolveComponent("PlayerView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.mobie)
      ? (_openBlock(), _createBlock(_component_PlayerView, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, " Do poprawnego działania odtwarzacza wymagana jest minimalna rozdzielczość 800x600px. "))
  ]))
}