import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f9eb6c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col s12 stageQuestions" }
const _hoisted_2 = { class: "row-flex" }
const _hoisted_3 = { class: "anim" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "anim",
  id: "progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.actualFrame.questions), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualFrame.answers, (item, ix) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "anim animBtn",
          key: ix
        }, [
          _createElementVNode("p", {
            class: "answerBtb",
            onClick: ($event: any) => (this.clickBlock &&
        _ctx.response($event, item.response))
          }, [
            _createElementVNode("span", null, _toDisplayString(item.text), 1)
          ], 8, _hoisted_4)
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
        [_vShow, !_ctx.mobile]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "anim",
        id: "timer"
      }, _toDisplayString(_ctx.thisTime), 513), [
        [_vShow, _ctx.mobile]
      ])
    ])
  ]))
}