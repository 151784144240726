<template>
  <div class="row">
      <div class="col s2 m3 l3 xl3"
      v-for="(item, ix) in frame"
      :key="ix">
        <img :class="[{'actualframe':(showFrame==item.stopTime)}, stageAction]"
        :src="require(`@/assets/${item.src}`)"
        @click="stage(item, ix)" alt="stage" @keydown="bar"  >
      </div>

  </div>
</template>
<style scoped>
img{
  width: 100%;
  cursor: pointer;
}
.actualframe{
  border:5px solid black;
}
</style>
<script>
/* eslint-disable */
  import {
    defineComponent
  } from 'vue';
    import {
    mapState
  } from 'vuex';
  import {
    mapGetters,
  } from 'vuex';
  export default defineComponent({
     data() {
      return {
      stageAction: "stageAction"

      }
    },
    setup() {

    },
    methods: {
      stage(item, ix) {
        this.$store.dispatch('addShowFrame', item.stopTime);  
        this.$store.dispatch('addActualFrame', item); 
        this.$store.dispatch('runStage', true); 
      }
    },
    mounted() {

    },
    computed: {
      ...mapState(['showFrame']),
      ...mapGetters({
        frame: 'frame',
      })
    }
  })

</script>
