
/* eslint-disable */
  import {
    mapState
  } from 'vuex';
  import {
    defineComponent
  } from 'vue';
  import {
    mapGetters,
  } from 'vuex';
  export default defineComponent({
    //   
    //   ...mapState(['maxPoint', 'earnedPoints', 'averageTime'])
    // 
    computed: {
      ...mapGetters({
        maxPoint: 'maxPoint',
        earnedPoints: 'earnedPoints',
        averageTime: 'averageTime'
      }),
    },
  })

