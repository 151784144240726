/* eslint-disable */
import {
  createStore
} from 'vuex';
import _, {
  map
} from 'underscore';
function decimal(n: number, k: number) {
  const factor = 10 ** (k + 1);
  const nn = Math.round(Math.round(n * factor) / 10);
  return nn / (factor / 10);
}
export default createStore({
  state: {
    frame: [],
    actualFrame: {},
    showFrame: 0,
    runStage: false,
    startPlayerTwoWay: false,
    timeArray:[],
    averageTime: "00,00",
    earnedPoints: 0,
    maxPoint: 0,
    replayScene:false,
    failScene:false,
    actualyTime:0,
    repeat:0,
    sound:true,
    mobile:false,
  },
  getters: {
    frame: state => state.frame,
    actualFrame: state => state.actualFrame,
    showFrame: state => state.showFrame,
    runStage: state => state.runStage,
    startPlayerTwoWay: state => state.startPlayerTwoWay,
    maxPoint: state => {
      const o = _.countBy(state.frame, (d) => {
        return d.showStage ? 'true' : 'false';
      });
      return state.maxPoint = (o.true) ?? 0
    },
    earnedPoints: state => {
      let o = _.countBy(state.frame, (d) => {
        return d.reply ? 'true' : 'false';
      });
      return state.earnedPoints = (o.true) ?? 0
    },
    averageTime: state => state.averageTime,
    replayScene: state=>state.replayScene,
    failScene:state=>state.failScene,
    actualyTime:state=>state.actualyTime,
    repeat:state=>state.repeat,
    sound:state=>state.sound,
    mobile:state=>state.mobile
  },
  mutations: {
    async updateFrame(state, payload) {
      state.frame = payload
    },
    async updateActualFrame(state, payload) {
      state.actualFrame = payload
    },
    async updateShowFrame(state, payload) {
      state.showFrame = payload
    },
    async updateRunStage(state, payload) {
      state.runStage = payload
    },
    async updateStartPlayerTwoWay(state, payload) {
      state.startPlayerTwoWay = payload
    },
    async updateEarnedPoints(state, payload) {
      var match = _.find(state.frame, function (item) {
        return item.stopTime === state.showFrame
      })
      if (match) {
        match.reply = payload;
      }
      state.actualyTime = 0;
      state.repeat = 0
      //state.startPlayerTwoWay = payload
    },
    async updateAnswerTime(state, payload) {
      state.timeArray.push(payload);
      let sum = state.timeArray.reduce((s, f) => {
        return s + f;             
    }, 0);  
    let t = sum/state.timeArray.length
    let ss = Math.floor((t / 1000) % 60)
    let ms = decimal((t / 1000 % 1) * 100, 0);
    let dss = (ss < 9) ? '0' + ss : ss;
    let dms = (ms < 9) ? '0' + ms : ms;
    const tim = dss + "," + dms;
    state.averageTime = tim;
    },
    async updateReplayScene(state, payload) {
      state.replayScene = payload
    },
    async updateFailScene(state, payload) {
      state.failScene = payload
    },
    async updateActualyTime(state, payload) {
      state.actualyTime += payload
    },
    async updateActualyTimeRestart(state, payload) {
      state.actualyTime = payload
    },
    async updateRepeatCounter(state) {
      state.repeat++
    },
    async updateSoundTriger(state) {
      state.sound = !state.sound
    },
    async updateMobileTriger(state, payload) {
      state.mobile = payload
    },
    
  },
  actions: {
    async addFrames({
      commit
    }, payload) {
      commit('updateFrame', payload);
    },
    async addActualFrame({
      commit
    }, payload) {
      commit('updateActualFrame', payload);
    },
    async addShowFrame({
      commit
    }, payload) {
      commit('updateShowFrame', payload);
    },
    async runStage({
      commit
    }, payload) {
      commit('updateRunStage', payload);
    },
    async startPlayerTwoWay({
      commit
    }, payload) {
      commit('updateStartPlayerTwoWay', payload);
    },
    async addPoint({
      commit
    }, payload) {
      commit('updateEarnedPoints', payload);
    },
    async addAnswerTime({
      commit
    }, payload) {
      commit('updateAnswerTime', payload);
    },
    async replayScene({
      commit
    }, payload) {
      commit('updateReplayScene', payload);
    },
    async failScene({
      commit
    }, payload) {
      commit('updateFailScene', payload);
    },
    async actualyTime({
      commit
    }, payload) {
      commit('updateActualyTime', payload);
    },
    async actualyTimeRestart({
      commit
    }, payload) {
      commit('updateActualyTimeRestart', payload);
    },
    async repeatCounter({
      commit
    }, payload) {
      commit('updateRepeatCounter');
    },
    async soundTriger({
      commit
    }, payload) {
      commit('updateSoundTriger');
    },
    async mobileTriger({
      commit
    }, payload) {
      commit('updateMobileTriger',payload);
    },
    
  },
  modules: {},
});
