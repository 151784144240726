
/* eslint-disable */
  import {
    defineComponent
  } from 'vue'
  import {
    mapState
  } from 'vuex';
  import _, {
    map
  } from 'underscore';
  import gsap from "gsap";
  import SceneList from '../components/SceneList.vue';
  import StagePlayer from '../components/StagePlayer.vue';
  import ScoreBar from '../components/ScoreBar.vue';
  import data from '../data/brakePoints.json';
  import "@/assets/but_0000_mute.png"
  import "@/assets/but_0001_sound.png"
  import "@/assets/size_0000_minimalize.png"
  import "@/assets/size_0001_maximalize.png"
  import "@/assets/subtitles_0000_subtitle_off.png"
  import "@/assets/subtitles_0001_subtitle_on.png"
  import "@/assets/en-subtitle.vtt"
  export default defineComponent({
    components: {
      SceneList,
      StagePlayer,
      ScoreBar
    },
    data() {
      return {
        runPlayer: false,
        player: undefined,
        currentTime: 0,
        stopMomentTime: 0,
        showStage: false,
        scennesPanel: false,
        videoPlayerHeight: 820,
        videoPlayerWidth: 1600,
        resize: true,
        subtitle: false,
        windowHeight: 0,
        windowWidth: 0
      }
    },
    setup() {

    },
    methods: {
      subtitleEvent() {
        if (this.subtitle) {
          $(".sub").removeClass("subOn");
          $(".sub").addClass("subOff");
          this.player.textTracks[0].mode = 'hidden';
        } else {

          $(".sub").addClass("subOn");
          $(".sub").removeClass("subOff");
          this.player.textTracks[0].mode = 'showing';
        }
        this.subtitle = !this.subtitle
      },
      mute(event) {
        if (this.sound) {
          $(".buttonSound").removeClass("sound");
          $(".buttonSound").addClass("mute");
          this.player.volume = 0;
        } else {
          $(".buttonSound").addClass("sound");
          $(".buttonSound").removeClass("mute");
          this.player.volume = 1;
        }

        this.$store.dispatch('soundTriger');
      },
      size(event) {
        if (this.resize) {
          $(".size").removeClass("maximalize");
          $(".size").addClass("minimalize");
          document.documentElement.requestFullscreen();
        } else {
          $(".size").removeClass("minimalize");
          $(".size").addClass("maximalize");

          document.exitFullscreen();
        }
        this.resize = !this.resize
      },
      showScenes() {
        this.scennesPanel = !this.scennesPanel;
        gsap.set(".stageAction", {
          "position": "relative"
        })
        gsap.fromTo(".stageAction", {
          opacity: 0,
          "top": 60
        }, {
          opacity: 1,
          "top": 0,
          duration: 0.5,
          ease: "power1.inOut",
          stagger: {
            from: "center",
            amount: 0.2
          }
        })
      },
      startPlayer() {
        gsap.set("#Player", {
          scale: 1,
        })
        gsap.set(".centralPlayer", {
          opacity: 1
        })
        if (!this.showStage) {
          let that = this;

          gsap.to(".centralPlayer", {
            opacity: 0,
            duration: 0.5,
            onComplete: function () {
              that.runPlayer = !that.runPlayer;
              (that.runPlayer) ? that.player.play(): that.player.pause();
            }
          });
        }
      },
      framePosition() {
        var show = _.findWhere(this.frame, {
          stopTime: this.currentTime + 1
        });
        if (show) {
          this.$store.dispatch('addShowFrame', show.stopTime);
          this.$store.dispatch('addActualFrame', show);
          this.showScene();
        }
      },
      showScene() {
        this.subtitle = false
        this.player.textTracks[0].mode = 'hidden';
        $(".sub").removeClass("subOn");
        $(".sub").addClass("subOff");
        this.scennesPanel = false
        this.showStage = false;
        var tl = gsap.timeline();
        // window.TL = gsap.timeline();
        tl.set("#Player", {
          scale: 1,
        })
        if (!this.actualFrame.showStage) {
          this.player.play();
          this.runPlayer = true;
        } else {
          this.runPlayer = false;
          this.player.pause();
          this.showStage = true;
          tl.to("#Player", {
            duration: 0.2,
          })
          tl.to("#Player", {
            scale: 1.2,
            duration: 1,
          })
        }
      },
      startPlayerAfterStage() {
        this.player.play();
        gsap.set("#Player", {
          scale: 1,
        })
        this.runPlayer = true;
      }

    },
    updated: function () {
      this.$nextTick(function () {
        console.log("asd")
      })
    },
    mounted() {
      var page = this;
      this.player = $("#Player").get(0);
      this.$store.dispatch('addFrames', data);
      this.player.ontimeupdate = (evt) => {
        const stopMomentTime = evt.target.currentTime;
        const actualTime = Math.ceil(evt.target.currentTime);
        if (actualTime != this.currentTime) {
          this.currentTime = actualTime;
          this.stopMomentTime = stopMomentTime;
          // console.log(this.currentTime)
          this.framePosition()
        }
      }
      gsap.set("#Player", {
        scale: 1,
      })

    },
    computed: {
      ...mapState(['actualFrame', 'frame', 'runStage', "showFrame", 'startPlayerTwoWay',
        'replayScene', 'failScene', "sound"
      ])
    },
    watch: {
      runStage(newValue, oldValue) {
        this.$store.dispatch('runStage', false);
        this.player.currentTime = this.showFrame;
        this.showScene();
      },
      startPlayerTwoWay(newValue, oldValue) {
        this.showStage = false;
        this.startPlayerAfterStage()
        this.$store.dispatch('startPlayerTwoWay', false);
      },
      replayScene(newValue, oldValue) {
     
        this.$store.dispatch('replayScene', false);
        this.showStage = false;
        this.player.currentTime = this.stopMomentTime - 3;
        this.player.play();
      },
      failScene(newValue, oldValue) {
        this.$store.dispatch('failScene', false);
        this.showStage = false;
        this.player.currentTime = 1200
        this.player.play();
        this.runPlayer = true;
        gsap.set("#Player", {
          scale: 1,
        })
      }
    }
  })

